/* eslint-disable */
import { getUserInfo, login } from '@/api/sys'
import {
  TOKEN,
  TOKEN_TIMEOUT,
  TENANT_ID,
  USER_INFO,
  TENANT_TYPE,
  IS_HDL,
  PLATFORM_TYPE,
  TENANTID,
  THIRD_TENANTID
} from '@/config/constant'
import { setItem, getItem, removeAll } from '@/utils/storage'
import { signOut } from '@/utils'

export default {
  namespaced: true,
  state: (): any => ({
    // token: getItem(TOKEN) || '',
    token:
      process.env.NODE_ENV === 'development'
        ? 'a2dfff9aa74247ef9d2fabcf0f5a1dc6'
        : getItem(TOKEN),
    // token: 'b1d8f40088334062926403ee78bd4c38',
    userInfo: getItem(USER_INFO) || {},
    thirdTenantId:
      process.env.NODE_ENV === 'development'
        ? '2fc4625fe49d487ea79a13c440e0c7eb'
        : getItem(THIRD_TENANTID),
    // thirdTenantId: '2fc4625fe49d487ea79a13c440e0c7eb',
    tenantId:
      process.env.NODE_ENV === 'development' ? '137' : getItem(TENANT_ID),
    // tenantId: 137,
    tokenCreateTime: getItem(TOKEN_TIMEOUT),
    tenantType: getItem(TENANT_TYPE),
    isHdl: getItem(IS_HDL),
    platformType: getItem(PLATFORM_TYPE)
  }),
  mutations: {
    setToken(state: any, token: string): void {
      state.token = token
      setItem(TOKEN, token)
    },
    setThirdTenantId(state: any, thirdTenantId: string): void {
      state.thirdTenantId = thirdTenantId
      setItem(THIRD_TENANTID, thirdTenantId)
    },
    setUserInfo(state: any, userInfo: string): void {
      state.userInfo = userInfo
      setItem(USER_INFO, userInfo)
    },
    setTokenCreateTime(state: any, tokenCreateTime: number): void {
      state.tokenCreateTime = tokenCreateTime
      setItem(TOKEN_TIMEOUT, tokenCreateTime)
    },
    setTenantId(state: any, tenantId: string | number): void {
      state.tenantId = tenantId
      setItem(TENANT_ID, tenantId)
    },
    setTenantType(state: any, tenantType: string): void {
      state.tenantType = tenantType
      setItem(TENANT_TYPE, tenantType)
    },
    setIsHdl(state: any, isHdl: boolean): void {
      state.is_hdl = isHdl
      setItem(IS_HDL, isHdl)
    },
    setPlatformType(state: any, platformType: string): void {
      console.log('111 ,', platformType)
      state.platformType = platformType
      setItem(PLATFORM_TYPE, platformType)
    }
  },
  actions: {
    async login(context: any, data: any) {
      const res = await login(data)
      console.log('res', res)
      if (res.token) {
        // @ts-ignore
        this.commit('user/setToken', res.token)
        // @ts-ignore
        this.commit('user/setTokenCreateTime', Date.now())
        // @ts-ignore
        this.commit('user/setTenantId', res.tenantId)
        // @ts-ignore
        this.commit('user/setUserInfo', res.userInfo)
        // @ts-ignore
        this.commit('user/setTenantType', data.platformType)
        // @ts-ignore
        this.commit('user/setIsHdl', res.hdl)
        // @ts-ignore
        this.commit('user/setThirdTenantId', res.thirdTenantId)
        console.log('this', res.hdl)
      }
      return res
    },
    async getUserInfo() {
      const res = await getUserInfo()
      // @ts-ignore
      // this.commit('user/setUserInfo', res.user)
      // 北京十九店
      res && this.commit('user/setUserInfo', res.user)
      return res
    },
    async loginOut() {
      // @ts-ignore
      this.commit('user/setToken', '')
      // @ts-ignore
      this.commit('user/setTokenCreateTime', 0)
      removeAll()
      // if ((router.currentRoute as any)._value.name === 'Home') {
      //   router.go(0)
      // } else {
      //   router.replace('/')
      // }
      signOut()
    }
  }
}

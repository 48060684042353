import axios from 'axios'
import { Toast } from 'vant'

import { comAsyncDialog, signOut } from '@/utils'
import { getItem } from '@/utils/storage'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + '/admin-api/',
  // baseURL: process.env.VUE_APP_BASE_API + '/',
  timeout: 30000
})

service.interceptors.request.use(
  (config: any) => {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      overlay: true
    })
    if (
      store.getters.token &&
      !config.headers.userURL &&
      config.url !== '/system/third-login'
    ) {
      config.headers.Authorization = `Bearer ${store.getters.token}`
      config.headers['tenant-id'] = store.getters.tenantId
    }
    if (config.headers.userURL) {
      config.url = config.headers.userURL + config.url
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  async (response: any) => {
    Toast.clear()
    const { code, msg, data, rows, total } = response.data
    if (code === 0 || code === 200) {
      Toast.clear()
      if (response.config.url === '/getInfo') {
        return {
          permissions: response.data.permissions,
          roles: response.data.roles,
          user: response.data.user
        }
      }
      return data || (total == null ? rows : null) || { rows, total }
    } else if (code === 1002000006 || code === 401) {
      Toast.clear()
      await comAsyncDialog(msg)
      signOut()
      return Promise.reject(msg)
    } else if (code === 1002000100) {
      await comAsyncDialog('用户ticket信息不能为空')
      signOut()
      return Promise.reject(msg)
    } else if (code === 1002000101) {
      await comAsyncDialog('未查询到对应的租户信息，请先授权')
      signOut()
      return Promise.reject(msg)
    } else if (code === 1002000102) {
      await comAsyncDialog(
        '未查询到对应仓颉租户信息，请先配置第三方app企业名称'
      )
      signOut()
      return Promise.reject(msg)
    } else if (code === 1002000103) {
      await comAsyncDialog('第三放用户app手机号为空，请配置')
      signOut()
      return Promise.reject(msg)
    } else if (code === 100202207) {
      await comAsyncDialog('未查询到仓颉用户信息，请在仓颉管理平台开通账号')
      signOut()
      return Promise.reject(msg)
    } else if (code === 100202209) {
      await comAsyncDialog('跳转仓颉应用，大华clientId为空，请联系开发人员')
      signOut()
      return Promise.reject(msg)
    } else if (code === 1002000104) {
      await comAsyncDialog('未查询到对应的授权用户信息，请先授权')
      signOut()
      return Promise.reject(msg)
    } else {
      Toast.fail(msg || '接口报错')
      return Promise.reject(msg)
    }
  },
  (error) => {
    Toast.fail(error.message)
    return Promise.reject(error)
  }
)

export default service

import { Dialog, Toast } from 'vant'
import store from '@/store'
import { TOKEN_TIMEOUT_VALUE } from '@/config/constant'

export const copyToClipboard = (text: string): void => {
  navigator.clipboard?.writeText && navigator.clipboard.writeText(text)
}

export const getDateTimeStamp = (dateStr: string): number => {
  return Date.parse(dateStr.replace(/-/gi, '/'))
}

export const getDateDiff = (dateTimeStamp: number): string => {
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  const month = day * 30
  const now = new Date().getTime()
  const diffValue = now - dateTimeStamp
  if (diffValue < 0) {
    return ''
  }
  const monthC = diffValue / month
  const weekC = diffValue / (7 * day)
  const dayC = diffValue / day
  const hourC = diffValue / hour
  const minC = diffValue / minute
  let result
  if (monthC >= 1) {
    result = '' + parseInt(String(monthC)) + '月前'
  } else if (weekC >= 1) {
    result = '' + parseInt(String(weekC)) + '周前'
  } else if (dayC >= 1) {
    result = '' + parseInt(String(dayC)) + '天前'
  } else if (hourC >= 1) {
    result = '' + parseInt(String(hourC)) + '小时前'
  } else if (minC >= 1) {
    result = '' + parseInt(String(minC)) + '分钟前'
  } else {
    result = '刚刚'
  }
  return result
}

const formatNumber = (n: number) => {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}

export const formatTime = (timeStamp: number, type = ''): string => {
  if (!timeStamp) return ''
  timeStamp = String(timeStamp).length === 10 ? timeStamp * 1000 : timeStamp
  const date = new Date(timeStamp)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const hour = date.getHours()
  const minute = date.getMinutes()
  // const second = date.getSeconds()

  const t1 = [year, month, day].map(formatNumber).join('-')
  const t2 = [hour, minute].map(formatNumber).join(':')

  switch (type) {
    case '':
      return `${t1} ${t2}`
    case 'date':
      return `${t1}`
    case 'time':
      return `${t2}`
    default:
      return ''
  }
}

export const getValueBykey = (
  key: string,
  val: string,
  arr: any,
  key2: string
): string => {
  if (!arr || !arr.length) return ''
  for (const item of arr) {
    // eslint-disable-next-line eqeqeq
    if (item[key] == val) {
      return item[key2]
    }
  }
  return ''
}

// 添加水印
export const addWaterMark = (ele: HTMLElement, str: string): void => {
  const canvas = document.createElement('canvas')
  ele.appendChild(canvas)

  canvas.width = 165
  canvas.height = 95
  canvas.style.display = 'none'
  const cans = canvas.getContext('2d') as CanvasRenderingContext2D
  cans.rotate((-20 * Math.PI) / 180)
  cans.font = '12px Microsoft JhengHei'
  cans.fillStyle = 'rgba(17, 17, 17, 0.2)'
  cans.textAlign = 'left'
  cans.textBaseline = 'middle'
  cans.fillText(str, 30, 105, 200)
  // ele.style.backgroundImage = 'url(' + canvas.toDataURL('image/png') + ')'
  const div: HTMLElement | null = document.querySelector('.mark')
  if (div) {
    div.style.backgroundImage = 'url(' + canvas.toDataURL('image/png') + ')'
  }
}

export const getBase64Image = (img: HTMLImageElement): string => {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext('2d')
  ;(ctx as CanvasRenderingContext2D).drawImage(img, 0, 0, img.width, img.height)
  const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
  const dataURL = canvas.toDataURL('image/' + ext)
  return dataURL
}

export const isAndroid = (): boolean => {
  const u = navigator.userAgent
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
}

export const comDialog = (message: string, callback: any): void => {
  Dialog.alert({
    message,
    theme: 'round-button',
    confirmButtonColor: '#fe7001'
  }).then(() => {
    callback()
  })
}

export const comAsyncDialog = (message: string, callback: any): any => {
  return Dialog.alert({
    message,
    theme: 'round-button',
    confirmButtonColor: '#fe7001'
  }).then(() => {
    callback()
  })
}

export const isCheckTimeout = (): boolean => {
  // if (!store.getters.tokenCreateTime) return false
  return Date.now() - store.getters.tokenCreateTime > TOKEN_TIMEOUT_VALUE
}

export const signOut = (): void => {
  try {
    if (store.getters.tenantType === 'daHua') {
      const params = JSON.stringify({ event: 'close' })
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(window as any).ReactNativeWebView.postMessage
        ? (window as any).ReactNativeWebView.postMessage(params)
        : (window as any).postMessage(params)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-extra-semi
      ;(window as any).YMApi.window.close()
    }
  } catch (error) {}
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/index.less'

import { vant } from '@/config/vant.config'
import '@/assets/styles/vant.css'

import Vconsole from 'vconsole'

const app = createApp(App)
if (process.env.NODE_ENV !== 'production') {
  const vConsole = new Vconsole()
  app.use(vConsole as any)
}
vant(app)
app.use(store).use(router).mount('#app')

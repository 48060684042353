export const setItem = <T>(key: string, value: T): void => {
  window.localStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : String(value)
  )
}

export const getItem = (key: string): any => {
  const data: string | null = window.localStorage.getItem(key)
  if (!data) return null
  try {
    return JSON.parse(data)
  } catch (error) {
    return data
  }
}

export const removeItem = (key: string): void => {
  window.localStorage.removeItem(key)
}

export const removeAll = (): void => {
  window.localStorage.clear()
}

/* eslint-disable */
import { STORE_NO } from '@/config/constant'
import { setItem, getItem } from '@/utils/storage'

export default {
  namespaced: true,
  state: (): any => ({
    storeNo: getItem(STORE_NO)
  }),
  mutations: {
    setStoreNo(state: any, storeNo: string): void {
      state.storeNo = storeNo
      setItem(STORE_NO, storeNo)
    }
  }
}

import request from '@/utils/request'

export const login = (data: any): any => {
  return request({
    url: '/system/third-login',
    method: 'post',
    data
  })
}

export const getUserInfo = (): any => {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

export const uploadBase64 = (data: any): any => {
  return request({
    url: '/config/oss-deploy/third/base64/upload',
    method: 'post',
    data
  })
}

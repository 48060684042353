import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index_v2.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/warning',
    name: 'Warning',
    component: () => import('@/views/warning/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/analysis/:type',
    name: 'analysisDetail',
    component: () => import('@/views/analysis/detail/index.vue')
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: () => import('@/views/analysis/index.vue')
  },
  {
    path: '/info/detail',
    name: 'InfoDetail',
    component: () => import('@/views/retrospect/detail/index.vue')
  },
  {
    path: '/retrospect/:id',
    name: 'Retrospect',
    component: () => import('@/views/retrospect/index.vue')
  },
  {
    path: '/retrospect/video/:id',
    name: 'RetrospectVideo',
    component: () => import('@/views/retrospect/video/index.vue')
  },
  {
    path: '/board',
    name: 'Board',
    component: () => import('@/views/board/index.vue')
  },
  {
    path: '/board/detail',
    name: 'BoardDetail',
    component: () => import('@/views/board/detail/index.vue')
  },
  {
    path: '/shop/:id',
    name: 'Shops',
    component: () => import('@/views/shop/manage/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('@/views/shop/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import('@/views/home/message/index.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/product/index.vue')
  },
  {
    path: '/task/add',
    name: 'TaskAdd',
    component: () => import('@/views/task/add/index.vue')
  },
  {
    path: '/task/list',
    name: 'TaskList',
    component: () => import('@/views/task/list/index.vue')
  },
  {
    path: '/task/detail',
    name: 'TaskDetail',
    component: () => import('@/views/task/detail/index.vue')
  },
  {
    path: '/404',
    name: 'NotFount',
    component: () => import('@/views/404/index.vue')
  },
  {
    path: '/:pathMath(.*)', // 此处需特别注意置于最底部
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  if (
    window.history.state?.forward &&
    window.history.state?.current === '/warning?type=1'
  ) {
    // to.meta.isBack = false
  } else if (window.history.state?.forward) {
    to.meta.isBack = true
  } else {
    to.meta.isBack = false
  }
})

export default router

export const TOKEN = 'token'
export const TOKEN_TIMEOUT_VALUE = 500 * 60 * 1000
export const TOKEN_TIMEOUT = 'token_timeout'
export const TENANT_ID = 'tenant_id'
export const USER_INFO = 'user_info'
export const STORE_NO = 'store_no'
export const TENANT_TYPE = 'tenant_type'
export const IS_HDL = 'is_hdl'
export const PLATFORM_TYPE = 'platform_type'
export const TENANTID = 'tenantId'
export const THIRD_TENANTID = 'third_tenantId'

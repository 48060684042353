/* eslint-disable */
const getters = {
  token: (state: any) => state.user.token,
  hasUserInfo: (state: any) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  storeNo: (state: any) => state.store.storeNo,
  nickName: (state: any) => state.user.userInfo.hikName,
  phonenumber: (state: any) => state.user.userInfo.hikPhoneNumber,
  tokenCreateTime: (state: any) => state.user.tokenCreateTime,
  tenantId: (state: any) => state.user.tenantId,
  tenantType: (state: any) => state.user.tenantType,
  isHdl: (state: any) => state.user.isHdl,
  platformType: (state: any) => state.user.platformType,
  thirdTenantId: (state: any) => state.user.thirdTenantId
}

export default getters
